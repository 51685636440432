import React, { useState, useEffect } from 'react';

const BrowserInfo = () => {
  const [browserInfo, setBrowserInfo] = useState({
    browserType: 'Unknown',
    browserVersion: 'Unknown',
    operatingSystem: 'Unknown',
    osVersion: 'Unknown'
  });

  useEffect(() => {
    const userAgent = navigator.userAgent;

    // Get browser type and version
    let browserType = 'Unknown';
    let browserVersion = 'Unknown';
    const browserRegex = /(Chrome|Safari|Firefox|Edge|OPR)\/(\S+)/;
    const browserMatch = userAgent.match(browserRegex);
    if (browserMatch && browserMatch.length >= 3) {
      browserType = browserMatch[1];
      browserVersion = browserMatch[2];
    }

    // Get operating system and version
    let operatingSystem = 'Unknown';
    let osVersion = 'Unknown';
    const osRegex = /(Windows NT|Mac OS X|Linux|Macintosh|CrOS) (\S+)/;
    const osMatch = userAgent.match(osRegex);
    if (osMatch && osMatch.length >= 3) {
      operatingSystem = osMatch[1];
      osVersion = osMatch[2];
    }

    setBrowserInfo({
      browserType,
      browserVersion,
      operatingSystem,
      osVersion
    });
  }, []);

  return (
    <div>
      <p class="bs-heading-2">Browser Type: {browserInfo.browserType}</p>
      <p  class="bs-heading-2">Browser Version: {browserInfo.browserVersion}</p>
      <p  class="bs-heading-2">Operating System: {browserInfo.operatingSystem} {browserInfo.osVersion}</p>
    </div>
  );
};

export default BrowserInfo;
