import logo from './images/logo.png';
import './App.css';

function StaticHeader() {
return (
<header>
  <div class="header">
    <div class="column-1">
      <img src={logo} className="App-logo" alt="logo" />
    </div>
    <div class="column-2">
    <a href="https://www.amusemore.com/" target="_blank" rel="noreferrer"><button class="button-50">Need Help</button></a>
    </div>

  </div>
</header>
)
};

export default StaticHeader;