// BrowserSize.js
import React, { useState, useEffect } from 'react';

const BrowserSize = () => {
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight
  });

  const updateWindowSize = () => {
    setWindowSize({
      width: window.innerWidth,
      height: window.innerHeight
    });
  };

  useEffect(() => {
    // Add event listener to update window size on resize
    window.addEventListener('resize', updateWindowSize);

    // Clean up event listener on unmount
    return () => {
      window.removeEventListener('resize', updateWindowSize);
    };
  }, []);

  

  return (
    <div class="browser-size-container">
      <h1 class="bs-heading-3">Your Browser Resolution is</h1>
        <div class="browser-container">
           <p class="bs-heading-start"> {windowSize.width}</p>
           <p class="bs-heading1-after">×</p>
           <p class="bs-heading-end"> {windowSize.height} </p>
        </div>

        <div>
           <p class="bs-heading-2"> Your Website Browser Width is <span class="browser-size-text"><b>{windowSize.width}</b></span> px</p>
           <p class="bs-heading-2"> Your Website Browser Height is <span class="browser-size-text"><b>{windowSize.height}</b></span> px</p>
        </div>
    
    
              
    </div>

  );
};

export default BrowserSize;
