import './App.css';

function Footer() {
return (
<footer>
  <div class="footer">
    <p class="bs-heading-2"> All Right are reserved.</p>
  </div>
</footer>
)
};

export default Footer;