import './App.css';
import './MobileApp.css'
import BrowserSize from './BrowserSize.js';
import DeviceType from './Browsertype.js';
import BrowserInfo from './BrowserInfo.js';
import IPDetails from './NetworkInfo.js';

function MainContent () {
    return (
        <div class="container-tool-wrapper"> 
             <div class="container-tool main"> 
                <div class="tool">
                   <BrowserSize />
                </div>
             </div>
            
            <div class="container-tool sidebar">
                <div class="tool-2">  
                   <h1 class="bs-heading">Browser Information</h1>
                   <DeviceType />
                   <BrowserInfo />
                   <hr></hr>
                   <h1 class="bs-heading">Network Information</h1>
                   <IPDetails />
                </div>
            </div>
        </div>

    );
};

export default MainContent;