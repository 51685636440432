import './App.css';
import './MobileApp.css'
import React from 'react';
import StaticHeader from './header.js';
import MainContent from './Maincontent.js';
import Footer from './Footer.js';

function App() {
  return (
    <div className="App">
       <StaticHeader />
       <MainContent />
       <Footer />
    </div>
  );
};

export default App;
