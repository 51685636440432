import React, { useState, useEffect } from 'react';

const DeviceType = () => {
  const [deviceType, setDeviceType] = useState('');

  useEffect(() => {
    const checkDeviceType = () => {
      if (window.matchMedia('(max-width: 767px)').matches) {
        setDeviceType('Mobile');
      } else if (window.matchMedia('(min-width: 768px) and (max-width: 1023px)').matches) {
        setDeviceType('Tablet');
      } else {
        setDeviceType('Desktop');
      }
    };

    checkDeviceType();

    const resizeHandler = () => {
      checkDeviceType();
    };

    window.addEventListener('resize', resizeHandler);

    return () => {
      window.removeEventListener('resize', resizeHandler);
    };
  }, []);

  return (
    <div className="device-container">
    <p class="bs-heading-2">Device Type: {deviceType}</p>
    </div>
  );
};


export default DeviceType;
