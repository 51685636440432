import React, { useState, useEffect } from 'react';
import axios from 'axios';

const IPDetails = () => {
  const [ipDetails, setIPDetails] = useState({
    ipv4: 'Loading...',
    ipv6: 'Loading...',
    isp: 'Loading...',
    country: 'Loading...',
    city: 'Loading...'
  });

  useEffect(() => {
    // Fetching IPv4 and IPv6 address using ipify API
    axios.get('https://api64.ipify.org?format=json')
      .then(response => {
        setIPDetails(prevState => ({
          ...prevState,
          ipv4: response.data.ip
        }));

        // Fetch additional details using ipapi for IPv4
        axios.get(`https://ipapi.co/${response.data.ip}/json/`)
          .then(ipApiResponse => {
            setIPDetails(prevState => ({
              ...prevState,
              country: ipApiResponse.data.country_name,
              city: ipApiResponse.data.city,
              isp: ipApiResponse.data.org
            }));
          })
          .catch(error => {
            console.error('Error fetching IP details:', error);
          });
      })
      .catch(error => {
        console.error('Error fetching IP address:', error);
      });

    // Fetching IPv6 address directly from the browser
    const getIPv6 = async () => {
      try {
        const { addresses } = await fetch('https://api64.ipify.org?format=json')
          .then(response => response.json());
        const ipv6Address = addresses.find(addr => addr.includes(':'));
        setIPDetails(prevState => ({
          ...prevState,
          ipv6: ipv6Address || 'N/A'
        }));
      } catch (error) {
        console.error('Error fetching IPv6 address:', error);
      }
    };

    getIPv6();
  }, []);

  return (
    <div>
      <p class="bs-heading-2">Network Provider: {ipDetails.isp}</p>
      <p class="bs-heading-2">Country: {ipDetails.country}</p>
      <p class="bs-heading-2">City: {ipDetails.city}</p>
      <p class="bs-heading-2">IPv4 Address: {ipDetails.ipv4}</p>
    </div>
  );
};

export default IPDetails;
